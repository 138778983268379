import { TextureLoader, CubeTextureLoader } from 'three'
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader'

import AssetManager from './AssetManager'

class Assets extends AssetManager {
  loaders = {
    textureLoader: new TextureLoader(),
    cubeTextureLoader: new CubeTextureLoader(),
    ktx2Loader: new KTX2Loader(),
  }
}

const assets = new Assets()
export default assets
