




type presets = { 
    toneMappingExposure: number, 
    fov: number ,
    timescale: number,
    uNumOctaves: number,
    uFrequency1: number,
    uFrequency2: number,
    uAmplitude1: number,
    uAmplitude2: number,
    uLimPower: number,
    uHeightShadeStart: number,
    uHeightShadeLength: number,
    metalness: number,
    roughness: number,
    envMapIntensity: number,
    flatShading: boolean,
    directionalLightIntensity: number,
    directionalLightColor: string,
    depthOfFieldEnabled: boolean,
    bokehScale: number,
    focalLength: number
}
const preset1 = {
        toneMappingExposure: 0.5,
        fov: 30,
        timescale: 0.7,
        uNumOctaves: 8,
        uFrequency1: 2.5,
        uFrequency2: 4,
        uAmplitude1: 0.3,
        uAmplitude2: 0.02,
        uLimPower: 0.8,
        uHeightShadeStart: 0.03,
        uHeightShadeLength: 0.15,

        metalness: 1,
        roughness: 0,
        envMapIntensity: 0.9,
        flatShading: false,

        directionalLightIntensity: 1,
        directionalLightColor: '#ffffff',
        depthOfFieldEnabled: true,
        bokehScale: 8,
        focalLength: 0.06
    }
const preset2 = {
        toneMappingExposure: 0.5,
        fov: 50,
        timescale: 1,
        uNumOctaves: 4,
        uFrequency1: 1.4,
        uFrequency2: 4.0,
        uAmplitude1: 0.3,
        uAmplitude2: 0.02,
        uLimPower: 0.8,
        uHeightShadeStart: 0.03,
        uHeightShadeLength: 0.15,

        metalness: 1,
        roughness: 0.,
        envMapIntensity: 0.9,
        flatShading: false,

        directionalLightIntensity: 1,
        directionalLightColor: '#ffffff',
        depthOfFieldEnabled: true,
        bokehScale: 8,
        focalLength: 0.06
    }
const preset3 = {    
        toneMappingExposure: 0.1,
        fov: 30,
        timescale: 0.5,
        uNumOctaves: 4,
        uFrequency1: 2.5,
        uFrequency2: 4,
        uAmplitude1: 0.47,
        uAmplitude2: 0.02,
        uLimPower: 0.1,
        uHeightShadeStart: 0.03,
        uHeightShadeLength: 0.15,

        metalness: 0.85,
        roughness: 0,
        envMapIntensity: 6,
        flatShading: false,

        directionalLightIntensity: 2,
        directionalLightColor: '#ffffff',
        depthOfFieldEnabled: true,
        bokehScale: 8,
        focalLength: 0.06
    }

const preset4 = {    
        toneMappingExposure: 0.1,
        fov: 50,
        timescale: 0.7,
        uNumOctaves: 4,
        uFrequency1: 2.5,
        uFrequency2: 4,
        uAmplitude1: 0.3,
        uAmplitude2: -0.07,
        uLimPower: 2.5,
        uHeightShadeStart: 0.03,
        uHeightShadeLength: 0.15,

        metalness: 0.9,
        roughness: 0.0,
        envMapIntensity: 3,
        flatShading: false,

        directionalLightIntensity: 230,
        directionalLightColor: '#ffffff',
        depthOfFieldEnabled: true,
        bokehScale: 8,
        focalLength: 0.06
    }

const presets = [
    preset1,
    preset2,
    preset3,
    preset4
]

export default presets